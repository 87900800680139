import useAuth from "../hooks/useAuth"
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";

import { useDispatch } from "react-redux";
import { useRefreshMutation } from "./authApiSlice";

const RequireAuth = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const dispatch = useDispatch()
    const [refresh] = useRefreshMutation()

    const accessToken = useSelector(selectCurrentToken)

    const [isAuth, setIsAuth] = useState(accessToken ? true : false);

    const handleRefresh = async () => {
        try {
            const data = await dispatch(refresh).unwrap()
            setIsAuth(data.data ? true : false)
        } catch (error) {
            console.log("Error: " + error)
        }
        dispatch(refresh)
    }

    useEffect(() => {
        handleRefresh();
    },[location])

   if(isAuth){
    return <Outlet />
   }else {
    navigate('/login')
   }
}

export default RequireAuth