import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import DashBoard from "./app/Components/pages/DashBoard";
import Inventory from "./app/Components/pages/Inventory";
import Reports from "./app/Components/pages/Reports";
import ItemsExerpt from "./app/features/items/ItemsExerpt";
import Sales from "./app/features/items/Sales";
import Debts from "./app/features/items/Debts";
import History from "./app/features/items/History";
import { useEffect } from "react";

import Layout from "../src/app/Components/pages/publicPages/Layout";
import Landing from "../src/app/Components/pages/publicPages/Landing";
import Login from "../src/app/Components/pages/publicPages/Login";
import Contact from "../src/app/Components/pages/publicPages/Contact";
import Privancy from "../src/app/Components/pages/publicPages/Privancy";
import TC from "../src/app/Components/pages/publicPages/TC";
import SignUp from "../src/app/Components/pages/publicPages/SignUp";

import RequireAuth from "./app/auth/RequireAuth";

import ProtectedLayout from "./app/Components/pages/ProtectedLayout";
import SplashScreen from "./app/Components/pages/SplashScreen";
import Help from "./app/Components/pages/publicPages/Help";
import Invoice from "./app/documents/Invoice";
import InvoiceForm from "./app/documents/InvoiceForm";
import PersistLogin from "./app/auth/PersistLogin";
import MissingRoute from "./app/Components/MissingRoute";

function App() {

  return (
    <Routes>
      {/* public routes */}
      {/* New tab routes */}
      <Route path="/privacy" element={<Privancy />} />
      <Route path="/tc" element={<TC />} />
      <Route path="/help" element={<Help />} />
      {/* New tab routes */}

      <Route element={<Layout />}>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/contact" element={<Contact />} />
      </Route>

      <Route element={<PersistLogin />} >
      <Route  path="/prefetch" element={<SplashScreen /> }/>
      <Route element={<RequireAuth />}>
        <Route path="/home" element={<ProtectedLayout />}>
          <Route path="dashboard" element={<DashBoard />} />
          <Route path="inventory" element={<Inventory />} />
          <Route path="stock" element={<ItemsExerpt />} />
          <Route path="sales" element={<Sales />} />
          <Route path="debts" element={<Debts />} />
          <Route path="reports" element={<Reports />} />
          <Route path="history" element={<History />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="invoiceform" element={<InvoiceForm />} />
        </Route>
      </Route>
      </Route>
      
      <Route path="*" element={<MissingRoute />} />
    </Routes>
 
  );
}

export default App;

// {
  
//       isLoading ?
//       <div className='splashScreen'>
     
//         <ReactLoading type="bars" color="gray" height={'30px'} width={'30px'} className=''  />
//        <br/>
//         <span>Loading...</span>
//         </div>
//    : ""
//    }


